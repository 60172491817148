var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('el-row',{attrs:{"gutter":12}},[_c('el-col',{attrs:{"sm":24,"md":12,"lg":6}},[_c('widget-card',{attrs:{"loading":false}},[_c('div',{staticClass:"filters"},[_c('span',[_vm._v("Filter dates")]),_c('el-date-picker',{attrs:{"type":"daterange","align":"right","unlink-panels":"","clearable":false,"size":"mini","format":"dd/MM/yyyy","range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date","picker-options":_vm.pickerOptions},model:{value:(_vm.date_range),callback:function ($$v) {_vm.date_range=$$v},expression:"date_range"}})],1)])],1),_c('el-col',{attrs:{"sm":24,"md":12,"lg":6}},[_c('widget-card',{attrs:{"loading":false}},[_c('div',{staticClass:"filters"},[_c('span',[_vm._v("Users")]),_c('div',{staticClass:"users"},[(!_vm.filter_users.length)?[_vm._l((Array(6).fill()),function(_,idx){return _c('div',{key:idx,staticClass:"avatar"})}),_c('div',{staticClass:"overlay"})]:_vm._l((_vm.filter_users),function(obj){return _c('div',{key:obj.user.id,staticClass:"avatar",class:obj.status,on:{"click":function($event){return _vm.toggleUser(obj)}}},[_c('avatar',{attrs:{"user":obj.user}})],1)})],2)])])],1),_c('el-col',{attrs:{"sm":24,"md":12,"lg":6}},[_c('widget-card',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"key-value"},[_c('label',[_vm._v("Spent by selected users")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("seconds2duration")(_vm.total_time_spent_filter_users))+" ")])])])],1),_c('el-col',{attrs:{"sm":24,"md":12,"lg":6}},[_c('widget-card',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                        _vm.seconds2hours(_vm.total_time_spent).toFixed(4)
                    ),expression:"\n                        seconds2hours(total_time_spent).toFixed(4)\n                    ",arg:"copy"}],staticClass:"total-hours",attrs:{"loading":_vm.loading},nativeOn:{"click":function($event){_vm.Notification({
                            type: 'success',
                            title: 'Copied',
                            message: `${_vm.seconds2hours(
                                _vm.total_time_spent
                            ).toFixed(4)} hours copied to clipboard.`,
                        })}}},[(_vm.has_reported_hours_rounding)?_c('div',{staticClass:"key-value"},[_c('label',[_vm._v("Total spent / reportable hours")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("seconds2duration")(_vm.total_time_spent))+" / "),_c('b',[_vm._v(" "+_vm._s(_vm._f("milli2duration")(_vm.total_time_reportable))+" ")])])]):_c('div',{staticClass:"key-value"},[_c('label',[_vm._v("Total time")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("seconds2duration")(_vm.total_time_spent))+" ")])])])],1)],1)],1),_c('section',[(_vm.loading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],attrs:{"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0)"}}):(_vm.filtered_daily_sessions.length)?_c('el-timeline',_vm._l((_vm.filtered_daily_sessions),function(day){return _c('el-timeline-item',{key:day.date.format('dddd, DD MMMM, YYYY'),attrs:{"timestamp":day.date.format('dddd, DD MMMM, YYYY'),"placement":"top"}},[_c('el-card',{attrs:{"shadow":"never"}},_vm._l((day.users),function(user_sessions,user_id){return _c('user-activity-block',{key:user_id,attrs:{"user_id":user_id,"sessions":user_sessions}})}),1)],1)}),1):_c('el-alert',{attrs:{"description":"There are no sessions for the selected period.","closable":false}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }